@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary: #01004b;
	--secondary: #182067;
	--tertiary: #eef3fb;
	--highlight: #8cf1ff;
	--muted: #989fc0;
	--lowlight: #ffcccc;
}

@layer base {
	:root {
		--radius: 0.5rem;
	}

	body {
		background: var(--primary);
		font-family: Arial, Helvetica, sans-serif;
	}

	::-moz-selection {
		color: white;
		background: var(--primary);
	}

	::selection {
		color: white;
		background: var(--primary);
	}
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}
